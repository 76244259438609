<template>
  <div id="page-auth" class="mt-8">
    <ErrorPopup :error="error_popup_message" />

    <div class="auth-header">
      <a href="#" class="logo">
        <img class="top-logo" :src="logoUrl" alt="Logo Preview" />
      </a>

      <!--<div class="auth-header-register">
        <span class="pr-2">Har du inget konto?</span>
        <router-link to="/ml-register" tag="a" class="line-link blue-link f-500"
          >Registrera</router-link
        >
      </div>-->
    </div>

    <div class="auth-body-wrapper">
      <router-view />
    </div>
    <div class="auth-footer">
      <router-link to="/" class="line-link gray-link f-500" v-html="copyright"
        ></router-link
      >
      <div>
        <router-link to="/terms" class="line-link blue-link f-500">Användarvillkor</router-link>
        <router-link to="/privacy-policy" class="line-link blue-link f-500 ml-8">Sekretesspolicy</router-link>
        <router-link to="/contact" class="line-link blue-link f-500 ml-8">Kontakt</router-link>

      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.top-logo {
  max-width: 340px;
  max-height: 180px;
  margin-bottom: 36px;
}

:deep .card-body {
  @media (max-width: 1024px) {
    padding: 1rem 0rem !important;
  }
}

:deep input {
  @media (max-width: 1024px) {
    height: 55px;
    font-size: 18px;
  }
}

#page-auth {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem;
  overflow-y: auto;
  @media (max-width: 1024px) {
    padding: 1.5rem 0.5rem;
    
  
  }



  .auth-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      padding: 1.5rem 0.5rem;
      .logo {
        margin-bottom: 2rem;
      }
    }
    .auth-header-register {
      color: #9d9fb1;
      font-size: 1.1rem !important;
      font-weight: 500;
    }
  }
  .auth-body-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 3rem;

    @media (max-width: 1024px) {
      padding: 0 0.5rem;
    }
  }
  .auth-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import ErrorPopup from '@/view/components/ErrorPopup.vue';
import { mapGetters } from 'vuex';


export default {
  name: 'auth',
  components: {
    ErrorPopup
  },
  computed: {
    ...mapGetters(['show_rate_limit_popup']),
    copyright() {
      return `© ${dayjs().format('YYYY')} ThinkingHat Studios AB`;
    },
    logoUrl() {
      if (this.customer_logo_url) {
        return this.customer_logo_url;
      } else {
        return require('@/assets/images/memlist-logo-svart-30.png');
      }
    },
  },

  mounted() {
    this.fetch_customer_login();
  },

  data() {
    return {
      customer_logo_url: null,
      error_popup_message: null,
    }
    
  },

  watch: {
    show_rate_limit_popup() {
      console.log('show_rate_limit_popup watcher', this.show_rate_limit_popup);
      if (this.show_rate_limit_popup) {
        this.error_popup_message = 'ERR_RATE_LIMIT';

        this.$nextTick(()=>{ this.error_popup_message = null; });
      }
    }
  },

  methods: {
    async fetch_customer_login() {
      const res = await axios.get(`/customer/login`);

      if (res.status === 200) {
        this.customer_logo_url = res.data.logo_url;
      }
    },
  }
};
</script>
